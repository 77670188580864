<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card" style="width: auto">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-1xl">Cambiar estatus de línea</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <p class="font-bold pb-3">
          ¿Estas seguro que deseas cambiar el estatus de la línea a
          {{ status.nombre }} ?
        </p>
        <ValidInput
          rules="required"
          label="¿Cual es el motivo?"
          v-model="form.motivo"
          label-class="font-light"
          type="text"
        />
        <valid-datepicker
          v-if="status.id == 5 || status.id == 8"
          label="Fecha de autorización"
          placeholder="Selecciona fecha..."
          v-model="form.fecha_autorizado"
          position="is-bottom-left"
        />
        <div v-if="status.id == 5 || status.id == 8">
          <ValidAutoComplete
            label="Instancia facultada"
            placeholder="Selecciona una instancia facultada..."
            rules="required"
            show="nombre"
            appendBody
            :custom="true"
            :data="getGruposEconomicosInternos.grupos"
            :expanded="true"
            @select="(instancia) => form.grupo_interno_id = instancia.id"
            @typing="buscarInstancia"
          />
        </div>
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          expanded
          type="is-primary"
          @click="validate().then(result => onConfirm(result))"
          >Cambiar estatus</b-button
        >
        <b-button expanded @click="$parent.close()">
          Cancelar
        </b-button>
      </section>
    </div>
  </ValidationObserver>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import ValidDatepicker from "@/components/form/ValidDatepicker";
import ValidInput from "@/components/form/ValidInput";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";

import { debounce } from "@/vendors/helpers";

export default {
  name: "ModalCambioStatusLinea",
  props: {
    status: Object
  },
  data() {
    return {
      form: {
        motivo: null,
        fecha_autorizado: null,
        grupo_interno_id: null
      },
      debeAgregarContrato: [2, 15, 22]
    };
  },
  methods: {
    async onConfirm(result) {
      if (!result) {
        return;
      }
      const { motivo, fecha_autorizado, grupo_interno_id } = this.form;

      const tieneContrato = this.getLinea.contrato_lineacredito_id || await this.$store.dispatch('lineascredito/getContrato', this.getLinea.id);

      if (
        this.debeAgregarContrato.includes(this.status.id) &&
        !tieneContrato
      ) {
        this.notification(
          "warning",
          "La línea no tiene contrato",
          `Debes asignar un contrato a la línea antes de cambiar a este estatus.`
        );
        return;
      }

      this.$store
        .dispatch("lineascredito/cambiarStatus", {
          tipo: this.getLinea.pasiva ? true : false,
          linea_id: this.$route.params.idLinea,
          status_nombre: this.status.nombre
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .split(" ")
            .join("_")
            .toLowerCase(),
          motivo,
          fecha_autorizado,
          grupo_interno_id
        })
        .then(data => {
          if (data) {
            this.$router.push({
              name: "detalle_linea"
            });
          }
        });
    },
    buscarInstancia: debounce(function(nombre) {
      if (!nombre.length) {
        return;
      }
      this.$store.dispatch("catalogos/getGruposEconomicosInternos", nombre);
    }, 400),
  },
  computed: {
    ...mapGetters("lineascredito", ["getLinea"]),
    ...mapGetters("catalogos", ["getGruposEconomicosInternos"])
  },
  async mounted() {
    if (this.getGruposEconomicosInternos == null) {
      await this.$store.dispatch("catalogos/getGruposEconomicosInternos");
    }
  },
  components: {
    ValidationObserver,
    ValidDatepicker,
    ValidInput,
    ValidAutoComplete,
  }
};
</script>
